<template>
  <v-dialog
    v-model="dialogCliente"
    @click:outside="$emit('update:dialogCliente', false)"
    @keydown.esc="$emit('update:dialogCliente', false)"
    scrollable
    width="1000"
  >
    <v-card>
      <v-card-title class="accent text-h5 font-weight-bold pa-4">
        <v-icon color="primary" left>mdi-account</v-icon>
        {{ cliente.nome_fantasia }}
        <v-spacer></v-spacer>
        <v-btn icon @click="$emit('update:dialogCliente', false)">
          <v-icon size="30">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-tabs v-model="tab">
        <v-tab>Dados</v-tab>
        <v-tab>Equipe</v-tab>
        <v-tab>Serviços</v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-card-text class="pa-7">
            <v-row>
              <v-col class="py-0" cols="12" sm="6">
                <v-text-field
                  label="Razão Social"
                  v-model="cliente.razao_social"
                  outlined
                  disabled
                  dense
                >
                </v-text-field>
              </v-col>

              <v-col class="py-0" cols="12" sm="6">
                <v-text-field
                  label="Nome Fantasia"
                  v-model="cliente.nome_fantasia"
                  disabled
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>

              <v-col class="py-0" cols="12" sm="3">
                <v-text-field
                  label="Ramo de Negócio"
                  v-model="cliente.ramo_negocio"
                  disabled
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>

              <v-col class="py-0" cols="12" sm="6">
                <v-text-field
                  label="E-mail"
                  v-model="cliente.email"
                  disabled
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>

              <v-col class="py-0" cols="12" sm="3">
                <v-text-field
                  label="Telefone"
                  v-model="cliente.telefone"
                  disabled
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>

              <v-col class="py-0" cols="12" sm="3">
                <v-text-field
                  label="Celular"
                  v-model="cliente.celular"
                  disabled
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>

              <v-col class="py-0" cols="12" sm="3">
                <v-text-field
                  label="Whatsapp"
                  v-model="cliente.whatsapp"
                  disabled
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>

              <v-col class="py-0" cols="12" sm="3">
                <v-text-field
                  label="Telegram"
                  v-model="cliente.telegram"
                  disabled
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>

              <v-col class="py-0" cols="12" sm="3">
                <v-text-field
                  label="Site"
                  v-model="cliente.site"
                  disabled
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>

              <v-col class="py-0" cols="12" sm="3">
                <v-text-field
                  label="Instagram"
                  v-model="cliente.instagram"
                  disabled
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>

              <v-col class="py-0" cols="12" sm="3">
                <v-text-field
                  label="Facebook"
                  v-model="cliente.facebook"
                  disabled
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>

              <v-col class="py-0" cols="12" sm="3">
                <v-text-field
                  label="Linkedin"
                  v-model="cliente.linkedin"
                  disabled
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>

              <v-col class="py-0" cols="12" sm="3">
                <v-text-field
                  label="Youtube"
                  v-model="cliente.youtube"
                  disabled
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
        </v-tab-item>

        <v-tab-item>
          <v-divider></v-divider>
          <v-card flat>
            <v-card-text>
              <v-data-table
                :headers="headersEquipe"
                :items="equipes"
                :items-per-page="10"
              >
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-tab-item>

        <v-tab-item>
          <v-container>
            <v-card outlined :loading="loadingServicos">
              <!-- <v-card-title class="text-subtitle-1 accent py-3">
                Serviços Prestados a esse cliente
              </v-card-title> -->
              <!-- <v-divider></v-divider> -->
              <v-card-text v-if="cliente_servicos.length" class="py-4">
                <div>
                  <v-chip
                    v-for="(servico, index) in cliente_servicos"
                    :key="index"
                    class="ma-1"
                    label
                  >
                    <v-icon left>mdi-account</v-icon>
                    {{ servico.servico }}
                  </v-chip>
                </div>
              </v-card-text>
              <v-card-text v-else class="pa-4">
                Nenhum serviço vinculado.
              </v-card-text>
            </v-card>
          </v-container>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-dialog>
</template>

<script>
import { fetchEquipes } from "@/api/colaborador/cliente_equipe.js";
import { fetchClienteServicos } from "@/api/colaborador/cliente_servicos.js";
export default {
  name: "ClienteColaborador",

  props: {
    dialogCliente: {
      type: Boolean,
    },
    item: {
      type: Object,
    },
  },

  data() {
    return {
      cliente: {},
      equipes: [],
      cliente_servicos: [],
      tab: 0,
      loadingServicos: false,
    };
  },
  computed: {
    headersEquipe() {
      return [
        {
          text: "Nome",
          value: "nome",
        },
        {
          text: "Cargo",
          value: "cargo.descricao",
        },
      ];
    },
  },
  methods: {
    getEquipes() {
      this.loading = true;
      return fetchEquipes(`?cliente_id=${this.item.id}`)
        .then((response) => {
          this.equipes = response;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    getClienteServicos() {
      this.loadingServicos = true;
      return fetchClienteServicos(this.item.id)
        .then((response) => {
          this.cliente_servicos = response;
          this.loadingServicos = false;
        })
        .catch(() => {
          this.loadingServicos = false;
        });
    },
  },

  async mounted() {
    this.cliente = { ...this.item };
    await this.getEquipes();
    await this.getClienteServicos();
  },
};
</script>

<style></style>
